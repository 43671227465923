// APP桥接方法
export default class AppBridge {
	// 去完成健身计划
	static toFinishFitness() {
		window.android && window.android.healthReport_toFinishFitness && window.android.healthReport_toFinishFitness()
		window.IOSBridge && window.IOSBridge.callHandler('healthReport_toFinishFitness')
		console.log('toFinishFitness')
	}
	// 佩戴手表，数据及时预警
	static jumpHealthWarning() {
		window.android && window.android.healthReport_jumpHealthWarning && window.android.healthReport_jumpHealthWarning()
		window.IOSBridge && window.IOSBridge.callHandler('healthReport_jumpHealthWarning')
		console.log('jumpHealthWarning')
	}
	// 导航返回事件
	static back() {
		window.android && window.android.healthReport_back && window.android.healthReport_back()
		window.IOSBridge && window.IOSBridge.callHandler('healthReport_back')
		console.log('back')
	}
	// 点击分享按钮
	static share(id) {
		window.android && window.android.healthReport_share && window.android.healthReport_share(id)
		window.IOSBridge && window.IOSBridge.callHandler('healthReport_share', id)
		console.log('share', id)
	}
	// 点击 实施佩戴手表监测数据 按钮
	static BLERealTimeData() {
		window.android && window.android.healthReport_BLERealTimeData && window.android.healthReport_BLERealTimeData()
		window.IOSBridge && window.IOSBridge.callHandler('healthReport_BLERealTimeData')
		console.log('BLERealTimeData')
	}
}