import router from '@/router'
import toast from '@/toast'
import store from '@/store'
import I18n from '@/i18n'


import { isShare } from '@/util'

// 路由跳转需要传递的参数
const shareKeys = [
	'token',
	'HR', 'HRTime',
	'SBP', 'DBP', 'BPTime',
	'sleep', 'sleepTime',
	'SaO2', 'SaO2Time',
	'Glu', 'GluTime',
	'BT', 'BTTime',
	'RR', 'RRTime',
	'P', 'PTime',
	'MET', 'METTime',
	'type', 'theme',
	'locale'
]

// 传递链接参数
router.beforeEach((to, form, next) => {
	if (shareKeys.find(k => !!to.query[k]) || !shareKeys.find(k => !!form.query[k])) {
		next()
	} else {
		const {
			token,
			HR, HRTime,
			SBP, DBP, BPTime,
			sleep, sleepTime,
			SaO2, SaO2Time,
			Glu, GluTime,
			BT, BTTime,
			RR, RRTime,
			P, PTime,
			MET, METTime,
			type, id,
			theme,
			locale,
		} = form.query
		next({
			...to,
			query: {
				token,
				HR, HRTime,
				SBP, DBP, BPTime,
				sleep, sleepTime,
				SaO2, SaO2Time,
				Glu, GluTime,
				BT, BTTime,
				RR, RRTime,
				P, PTime,
				MET, METTime,
				type, id,
				theme,
				locale,
				...to.query
			},
		})
	}
})

// 根据当前状态配置路由跳转，是否有报表、进行过过敏原调查、
router.beforeEach( async (to,from,next) => {
	console.log('111',await store.hasToken(to.query))
	if( await store.hasToken(to.query) ){
		if ( await store.hasUser() ) {
			// 有token和用户信息
			if (isShare(to.query.type)) {
				// 当前页面是分享状态
				if (/^\/criterion/.test(to.path)) {
					// 前往健康标准页面
					return next()
				} else if (to.query.id) {
					// 链接带有报表ID
					if (/^\/report\/conclusion/.test(to.path)) {
						// 前往报表页面
						return next()
					} else {
						// 重定向到报表页面
						return next({ ...to, path: '/report/conclusion' })
					}
				} else {
					throw new Error(I18n.t('reportId'))
				}
			} else if ( await store.hasHealth(to.query) ) {
				// 有最新的用户健康数据
				if (await store.hasReportId()) {
					// 有往期的报表数据
					if (/^\/allergy\/ask/.test(to.path)) return next({ ...to, path: '/report/data' })
				} else {
					// 没有往期的报表数据，新用户进行免疫调查
					if (!/^\/allergy/.test(to.path)) return next({ ...to, path: '/allergy/ask' })
				}
				return next()
			}else{
				return next({ ...to, path: '/allergy/ask' })
			}
		} else {
			toast.show(I18n.t('loginAgain'))
			throw new Error(I18n.t('linkNone'))
		}
	}else{
		toast.show(I18n.t('loginAgain'))
		return next({ ...to, path: '/error' })
	}
	// } catch (e) {
    //     console.log('@@@@@5')
	// 	// 以上执行错误重定向到错误页面
	// 	toast.show(e && e.message || e.toString())
	// 	return next({ ...to, path: '/error' })
	// }
})
