import 'normalize.css'
import 'lib-flexible'

import Vue from 'vue'

import AppBridge from '@/bridge'

import router from '@/router'
import toast from '@/toast'
import store from '@/store'
import i18n from '@/i18n'
import App from '@/App'
import '@/scss/theme.css';
import '@/permission'
// import VConsole from 'vconsole'


// const vConsole = new VConsole()
// Vue.use(vConsole)

// APP桥接方法
Vue.prototype.$bridge = AppBridge
// 消息toast
Vue.prototype.$toast = toast
// 全局数据存储
Vue.prototype.$store = store

Vue.config.productionTip = false

new Vue({
	// 多语言
	i18n,
	// 路由
	router,
	render: h => h(App),
}).$mount('#app')
