import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export const routes = [
	{
		path: '/',
		redirect: '/allergy/ask',
	},
	// 过敏调查
	{
		path: '/allergy/ask',
		component: () => import('./page/allergy/ask'),
	},
	{
		path: '/allergy/examine',
		component: () => import('./page/allergy/examine'),
	},
	// 健康报告
	{
		path: '/report/data',
		component: () => import('./page/report/data'),
	},
	{
		path: '/report/conclusion',
		component: () => import('./page/report/conclusion'),
	},
	// 历史记录
	{
		path: '/log',
		component: () => import('./page/log'),
	},
	// 健康标准说明
	{
		path: '/criterion',
		component: () => import('./page/criterion'),
	},
	// 错误页面
	{
		path: '/error',
		component: () => import('./page/error'),
	},
	// 未知页面
	{
		path: '*',
		redirect: '/error',
	},
]

const router = new VueRouter({
	mode: 'hash',
	base: './',
	routes,
})

// 重新push方法，防止重定向路由抛错
router._push = router.push
router.push = function(...args) {
	return this._push.call(this, ...args).catch(e => {
		if (/^redirected/.test(e && e.message.toLowerCase() || '')) return
		throw e
	})
}

export default router
