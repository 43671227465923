export const isEmpty = value => {
	if (typeof value === 'string' || Array.isArray(value)) {
		return !value.length
	} else if (typeof value === 'boolean' || typeof value === 'number') {
		return false
	} else if (value && typeof value === 'object') {
		return !Object.keys(value).length
	} else {
		return !value
	}
}

export const isShare = type => type === 'share'
