export default {
  lang: 'en-us',
  notFount: "Not Fount",
  loginAgain: 'Please re-login',
  linkNone: 'Missing link parameters',
  reportId: 'Report ID missing',
  createWait: {
    creating: 'Health report generation in progress',
  },
  dataPanel: {
    tip: 'Wear a watch in real time to monitor data',
  },
  dataStatus: {
    higher: '${num} high risk item',
    medium: '${num} medium risk item',
    low: '${num} low risk item',
    not: '${num} risk item',
    normal: 'All indicators are normal',
    separator: ',',
    date: 'Report generation time: ',
  },
  dataItem: {
    higher: 'High risk',
    medium: 'Medium risk',
    low: 'Low risk',
    normal: 'Normal',
  },
  listLoad: {
    over: "It's over",
    loading: 'Loading',
  },
  edit: 'Edit',
  confirm: {
    title: 'reminder',
    cancel: 'cancel',
    confirm: 'confirm',
  },
  dataStatusConfirm: {
    cancel: 'Check the data first',
    confirm: 'Generate Health Report',
    incomplete: 'It is detected that you only have height and weight data at present. Do you want to continue to generate health report?',
    noNew: 'It is detected that your following data is not up-to-date. Do you want to continue generating health reports?',
    old: 'It is detected that your following data was monitored 24 hours ago. Do you want to continue to generate health report?',
    bloodPressure: 'Last measurement time of blood pressure:<br/>',
    bloodOxygen: 'Last measurement time of blood oxygen:<br/>',
    heartRate: 'Last measurement time of heart rate:<br/>',
    sleep: 'Last measurement time of sleep:<br/>',
    bloodSugar: 'Last measurement time of blood glucose:<br/>',
    temperature: 'Last measurement time of body temperature:<br/>',
  },
  dataExplain: {
    next: 'next page',
    end: 'complete',
    page0: {
      cover: 'step_0_en',
      coverChuangwei: 'step_0_en_chuangwei',
      title: 'Health data, comprehensive collection',
      text: 'Comprehensively collect your health data, including heart rate, blood glucose, blood pressure, blood oxygen, sleep, etc',
      textChuangwei: 'Comprehensively collect your health data, including heart rate, blood oxygen, sleep, etc',
    },
    page1: {
      cover: 'step_1_en',
      title: 'Intelligent analysis, targeted improvement suggestions',
      text: 'Intelligently analyze health data and put forward targeted improvement suggestions according to risk level',
    },
    page2: {
      cover: 'step_2_en',
      title: 'Comprehensive warning, healthy life',
      text: 'Timely warning of health data and immunity to protect your healthy life',
    },
  },
  allergy: {
    appBar: 'Health Questionnaire',
    isItAllergic: 'Do you have any previous allergies?',
    yes: 'Yes',
    no: 'No',
    whatAllergies: 'Which of the following items are you allergic to?',
    inhalation: 'Inhaled allergens: ',
    ingestion: 'Ingested allergens: ',
    other: 'Other allergens: ',
    not: 'Other: ',
    next: 'next step',
    submit: 'Submit',
    tip: 'Please select the allergen first',
  },
  reportData: {
    appBar: 'One click Health Report',
    date: 'Last report generated',
    next: 'Generate the latest health report',
    sleep: 'Sleep for ${val} hour',
    bloodSugar: '${val}',
    breathingRate: '${val} times / min',
  },
  reportConclusion: {
    healthy: 'Health Report',
    diet: 'Dietary recommendations',
    motion: 'Sports advice',
    wear: 'Timely warning with Watch',
    plan: "To finish today's fitness program",
    exercise: "To finish today's exercise",
    causes: 'Cause',
    suggest: 'Suggestions for improvement',
    user: {
      age: '${val} years old',
      sex1: 'male',
      sex2: 'female',
    },
    dataItem: {
      diet: {
        title0: 'Eating habits and suggestions',
        title1: 'Recommended ingredients',
        title2: 'Ingredients are not recommended',
      },
      motion: {
        title0: 'Sports advice',
      },
    },
    allergen: 'Allergens:',
    noAllergen: 'No history of allergies',
    motionSuggest: 'Keeping regular exercise and rest can make your body healthier and stronger, and keep you young and energetic!'
  },
  log: {
    appBar: 'Historical records',
    date: '{y}-{m}',
  },
  criterion: {
    appBar: 'Health risk level description',
    tabBar: {
      heartRate: 'Heart rate',
      bloodPressure: 'Blood pressure',
      sleep: 'Sleep',
      bloodOxygen: 'Blood oxygen',
      bloodSugar: 'Blood sugar',
      weight: 'BMI',
      temperature: 'Temperature',
      breathingRate: 'Respiratory rate',
      pressure: 'Pressure',
      meto: 'Meto',
      immunity: 'Immunity',
    },
    panel: {
      heartRate: {
        label: 'Heart rate risk criteria',
        category0_40: 'Bradycardia',
        category40_50: 'Bradycardia',
        category50_60: 'Bradycardia',
        category60_100: 'Normal heart rate',
        category100_120: 'Tachycardia',
        category120_140: 'Tachycardia',
        category140_999: 'Tachycardia',
        default0_1: '< ${max} times / min',
        default1_1: '${min} times / min ≤ ${max} times / min',
        default1_0: '> ${min} times / min',
        health: 'Normal heart rate',
        healthText: 'According to the current monitoring results, the heart rate data is normal.<br>healthy adult<span>Normal heart rate is 60 to 100 beats per minute</span>，The ideal heart rate should be 55-70 beats/min (the heart rate of athletes is slower than that of ordinary adults, generally about 50 beats/min). When the heart rate is between 90-100, although it does not meet the standard of tachycardia, it also needs alert.',
        risk_gs: '1. According to the current monitoring results, if there are symptoms of abnormal heart rate, please pay attention to the state of your heart, and seek medical attention in time if necessary.<br>2. Tachycardia can cause<span> palpitations, conscious stoppage, chest tightness, shortness of breath, shortness of breath, drop in blood pressure, dizziness</span> more。',
        risk_gh: '1. According to the current monitoring results, if there are symptoms of abnormal heart rate, please pay attention to the state of your heart, and seek medical attention in time if necessary.<br>2, bradycardia may directly lead to<span> fainting, transient dizziness, headache, heart failure, or confusion</span>, Some patients may present with nonspecific manifestations such as chest pain, fatigue, and shortness of breath.',
      },
      bloodPressure: {
        label: 'Blood pressure risk criteria',
        highPressure: 'Systolic blood pressure (high pressure)',
        lowPressure: 'Diastolic blood pressure (low pressure)',
        category0_90: 'Low blood pressure',
        category90_120: 'Better blood pressure',
        category120_140: 'High blood pressure',
        category140_160: 'Hypertension grade 1',
        category160_180: 'Hypertension grade 2',
        category180_999: 'Hypertension grade 3',
        shrink0_1: '≤ ${max}',
        shrink1_1: '${min} < High pressure ≤ ${max}',
        shrink1_0: '> ${min}',
        diastole0_1: '≤ ${max}',
        diastole1_1: '${min} < Low pressure ≤ ${max}',
        diastole1_0: '> ${min}',
        health0: 'Normal blood pressure',
        healthText0: 'The blood pressure was counted nearly 200 times, and the blood pressure status was recorded as ${g}/${d}mmHg recently, and the current blood pressure was normal.',
        health1: 'Normal range',
        healthText1: 'Systolic blood pressure 90～139mmHg<br>Diastolic blood pressure 60～89mmHg <br><br>Hypertension is a "lifestyle disease, and many daily behaviors are risk factors for hypertension. High sodium and low potassium diet, overweight or obesity, excessive alcohol consumption, chronic stress, insufficient physical activity, As well as <span>family history of hypertension</span>, etc. can cause this disease.',
        risk_g: '1. The current blood pressure is ${g}/${d}mmHg, which is abnormal. You should be very alert to sudden illnesses. You <span>need to seek medical attention as soon as possible, or call 120 for emergency calls</span>.<br>2. High blood pressure may cause: dizziness, fatigue, insomnia, loss of appetite and indigestion. Severe cases can lead to: dizziness, vomiting, angina pectoris, difficulty breathing, and even fainting, etc. Insufficient blood supply to the brain can lead to <span>cerebral infarction and paralysis</span>。',
        risk_d: '1. The blood pressure has been counted nearly 200 times, and the blood pressure status has been recorded as ${g}/${d}mmHg recently. The current blood pressure is abnormal, and you need to seek medical treatment as soon as possible.<br>2. Low blood pressure may cause <span>dizziness, blurred vision, nausea</span>, fatigue, fear of cold, paleness and other symptoms.',
      },
      sleep: {
        label: 'Sleep risk criteria',
        sleepDuration: 'Sleep duration',
        category7_999: 'Normal sleep',
        category6_7: 'Less sleep time',
        category5_6: 'Less sleep time',
        category0_5: 'Less sleep time',
        default1_0: '≥ ${min} hours',
        default1_1: '${min} - ${max} hours',
        default0_1: '≤ ${max} hours',
        health: 'Sleep health value',
        healthText: 'Sleep is divided into <span>rapid eye movement</span> (REM) sleep and <span>non REM</span> (NREM) sleep. NREM sleep includes light sleep and deep sleep. In the process of sleep, REM sleep and NREM sleep appear alternately, and each alternation is called a sleep cycle. Exercise can improve sleep quality and increase effective sleep time.',
        risk: '1. Not enough sleep is easy to affect work and study during the day. It is easy to cause anxiety, depression and irritability.<br>2. <span>Reduced immunity, memory degradation</span>, causing health problems, diseases are easy to invade.',
        table: {
          head0: 'Sleep state',
          head1: 'Value / scale',
          row0_col0: 'Sleep often (at night)',
          row0_col1: '6-10 (hours)',
          row1_col0: 'Proportion of deep sleep',
          row1_col1: '20%-60%',
          row2_col0: 'Light sleep ratio',
          row2_col1: '<55%',
          row3_col0: 'Rapid eye movement ratio',
          row3_col1: '10%-30%',
        },
      },
      bloodOxygen: {
        label: 'Blood oxygen risk standard',
        bloodOxygenSaturation: 'Blood oxygen saturation',
        category90_999: 'Good blood oxygen / Normal blood oxygen',
        category80_90: 'Low blood oxygen',
        category70_80: 'Anoxic state',
        category0_70: 'Severe hypoxia',
        default1_0: '≥ ${min}%',
        default1_1: '${min}% ≤ Blood oxygen < ${max}%',
        default0_1: '< ${max}%',
        health: 'Normal blood oxygen',
        healthText: '1. The <span>normal oxygen saturation of the human body is 90% - 100%</span>. The higher the oxygen content in the blood, the better the human metabolism. <br>2. Of course, high blood oxygen content is not a good phenomenon. The blood oxygen in the human body has a certain saturation. Too low will lead to insufficient oxygen supply to the body, and too high will lead to cell aging in the body.',
        risk: 'At present, the blood oxygen is abnormal, and there will be mild hypoxia, chest tightness, shortness of breath and other symptoms.',
      },
      bloodSugar: {
        label: 'Blood glucose risk criteria',
        fastingBloodGlucose: 'Fasting blood glucose',
        preprandialBloodGlucose: 'preprandial blood glucose',
        postprandialBloodGlucose: 'postprandial blood glucose',
        randomBloodGlucose: 'random blood glucose',
        category0_40: 'Hypoglycemia',
        category40_44: 'Low blood sugar',
        category44_70: 'Normal blood sugar',
        category70_110: 'High blood sugar',
        category110_9990: 'High risk of blood glucose',
        category44_100: 'Normal blood sugar',
        category100_139: 'High blood sugar',
        category139_9990: 'High risk of blood glucose',
        category44_111: 'Normal blood sugar',
        category111_139: 'High blood sugar',
        imageLevel0_40: 'Hypoglycemia',
        imageLevel40_44: 'Hypoglycemia',
        imageLevel44_70: 'Normal',
        imageLevel70_110: 'Hyperglycemia',
        imageLevel110_9990: 'Hyperglycemia',
        default0_1: 'Blood sugar < ${max}',
        default1_1: '${min} ≤ Blood sugar < ${max}',
        default1_0: 'Blood sugar ≥ ${min}',
        health0: 'Normal Adult Blood Glucose Data',
        healthText0: 'Fasting blood glucose value is 4.0～7.0mmol/L<br>Postprandial blood glucose value is 4.0～10.0mmol/L',
        health1: 'Causes of high blood sugar',
        healthText1: '1. Eating sugary and starchy foods not only causes obesity, but also increases blood sugar.<br>2. Hyperglycemia and coronary heart disease caused by other diseases of the body are closely related to hyperglycemia.<br>3. Often <span>drinking and smoking, lack of exercise, obese</span> elderly people with high fat, etc.',
        health2: 'Causes of low blood sugar',
        healthText2: '1. Too much insulin injection and oral hypoglycemic drugs can promote insulin secretion.<br>2、Excessive exertion or exercising on an empty stomach causes blood sugar levels to drop in the body.<br>3、<span>Long-term staying up late and drinking alcohol, severe malnutrition</span> and prolonged absence of food lead to reduced exogenous glucose intake.',
        risk: '1. The current blood sugar is ${val}mmol/L, ${status}.',
        risk1: '<br>2. Low blood sugar fluctuations can lead to tissue hypoxia, induce cardiovascular and cerebrovascular diseases, cause falls and cause trauma, and even serious death.<br>3. Causes <span>hypoglycemia coma</span>, causing serious damage to the central nervous system',
        risk2: '<br>2. Causes lesions in various tissues and organs of the body, which may cause acute and chronic complications in patients.<br>3. The body is in a state of high blood sugar for a long time, and there may be three symptoms similar to diabetes, that is, polydipsia, polyphagia, polyuria, and body weight loss.',
      },
      weight: {
        label: 'BMI risk criteria',
        BMIValue: 'BMI value',
        category0_185: 'Lean weight',
        category185_240: 'Normal weight',
        category240_270: 'Overweight',
        category270_300: 'Weight obesity',
        category300_400: 'Severe obesity',
        category400_9990: 'Extreme obesity',
        default0_1: 'BMI < ${max}',
        default1_1: '${min} ≤ BMI < ${max}',
        default1_0: 'BMI ≥ ${min}',
        health0: 'Normal weight',
        healthText0: "Standard body weight is one of the important signs to reflect and measure a person's health status. Being too fat or too thin is not good for health, nor does it give people a sense of fitness.",
        health1: 'How to maintain a weight standard',
        healthText1: '1. Eat less sweets, such as candy and fried fast food.<br>2. <span>Eating more fruits and vegetables</span> will make you healthier and protect you from getting sick.<br>3. to eat more fish food and a variety of soy products.<br>4. Whether or not your weight reaches the standard state, you must do regular <span>physical exercise</span>. You can build your physique by running.',
        risk_1: 'Current BMI ${val}, ${status}, please pay attention to strengthen body nutrition.',
        risk1: 'Current BMI${val}, when 24.0≤BMI<30.0, ${status}.',
        risk2: 'The current BMI is ${val}, when 30.0≤BMI＜40.0, ${status}',
        risk3: 'Current BMI is ${val}, when BMI ≥ 40.0, ${status}',
      },
      temperature: {
        label: 'Body temperature risk criteria',
        bodyTemperatureValue: 'Body temperature value',
        category360_373: 'Normal temperature',
        category373_380: 'Low fever',
        category380_400: 'Moderate fever',
        category400_9990: 'High fever',
        default1_1: '${min}℃ ≤ Body temperature < ${max}℃',
        default1_0: 'Body temperature ≥ ${min}℃',
        health0: 'Normal body temperature',
        healthText0: 'The average human body temperature is between 36-37°C (armpit)<br>Above 37.3°C is fever<br>37.3-38°C is low-grade fever<br>38.～40°C is high fever<br>Above 40°C is alive at any time Danger.',
        health1: 'How to handle',
        healthText1: '1. It is not recommended to take antipyretic drugs directly orally for low-grade fever, because antipyretic drugs may hinder the development of immune function and have a wrong impact on the judgment of the disease. <br>2. If the body temperature has been in the low heat range below 38°C, the physical cooling method should be considered first, such as wiping the body with warm water, drinking more hot water, paying attention to indoor ventilation, reducing clothing, etc.;',
        health2: 'How to handle',
        healthText2: 'When the body temperature exceeds 38.5 ℃, especially when the body temperature of children is high, oral antipyretic drugs are required to cool down. At present, the commonly used antipyretic drugs in clinical practice are paracetamol, ibuprofen, aspirin, etc.',
        health3: 'How to handle',
        healthText3: "When the body temperature exceeds 40°C, you should seek medical attention as soon as possible to diagnose the root cause of the fever. Then, according to the doctor's advice, choose the anti-fever medicine that suits you.",
        risk1: 'Your current body temperature is ${val}°C. When 37.3°C≤body temperature＜38.0°C, it is a low-grade fever. Please take rest to cool down.',
        risk2: 'Your current body temperature is ${val}°C. When 38.0°C≤body temperature＜40.0°C, it is a high fever state, please take rest and cool down.',
        risk3: 'Your current body temperature is ${val}°C. When the body temperature is greater than 40.0°C, it is in a state of severe high fever, which is in danger of life at any time. Please pay attention to timely medical treatment and cooling.',
      },
      breathingRate: {
        label: 'Respiratory rate risk criteria',
        respiratoryRateValue: 'Respiratory rate value',
        category0_12: 'Low respiratory rate',
        category12_20: 'Normal respiratory rate',
        category20_999: 'High respiratory rate',
        default0_1: '< ${max} Times / minute',
        default1_1: '${min} Times / minute ≤ Respiratory rate < ${max} Times / minute',
        default1_0: '> ${min} Times / minute',
        harm: 'Harm',
        health0: 'Normal respiratory rate',
        healthText0: 'The respiratory rate of normal adults is 12 ~ 20 times per minute.',
        health1: 'Please be careful not to breathe too fast',
        healthText1: 'Excessive breathing rate refers to more than 24 times per minute. Generally, after strenuous activities, fever, anemia, hyperthyroidism, cardiac insufficiency, or mental tension, there will be too fast breathing rate. If you are in good health at ordinary times and there is no obvious incentive to breathe too fast, it may be caused by water stress or excessive pressure.',
        risk: 'Your current breathing rate is <span>${val} times / min</span>, which is too high. Please relax and take a deep breath.',
      },
      pressure: {
        label: 'Pressure risk criteria',
        pressureValue: 'Pressure value',
        category1_60: 'Normal / relaxed pressure',
        category60_80: 'Medium pressure',
        category80_99: 'High pressure',
        default1_1: '${min} ≤ Pressure < ${max}',
        health0: 'Easy',
        healthText0: '1-29 is relaxed; 30-59 is normal; 60-79 is moderate; 80-99 is high',
        health1: 'Normal pressure',
        healthText1: '1-29 is relaxed; 30-59 is normal; 60-79 is moderate; 80-99 is high',
        health2: 'Stress relief method',
        healthText2: '1. <span>Enhance physical activity</span> and enhance physical fitness.<br>2. <span>Listen to soothing music</span> and watch comedy movies to help you relax.<br>3. Take a hot bath, sleep, and let the body rest.<br>4. <span>Talk to a friend</span>, tell your troubles, and release the pressure in your heart.',
        health3: 'High pressure hazards',
        healthText3: '1. Inattention, decreased memory, decreased comprehension and creativity; frequent worry, irritability, anxiety.<br>2. lead to various heart diseases: increased blood lipids, increased blood clots, heart disease, stroke.<br>3. It affects the vitality of the immune system and has a great impact on the skin.',
        risk2: 'At present, your <span>stress value is ${val}</span>, which is a moderate stress state',
        risk3: 'At present, your <span>stress value is ${val}</span>, which is a high stress state. Please take a deep breath and stay in a good mood.',
      },
      meto: {
        label: 'Meto risk criteria',
        category0_3: 'Mets low intensity',
        category3_6: 'Mets medium intensity',
        category6_999: 'Mets high intensity',
        default0_1: '≤ ${max} mets',
        default1_1: '${min} mets < Meto ≤ ${max} mets',
        default1_0: '> ${min} mets',
        health0: 'The benefits of exercise',
        healthText0: '1. Maintaining regular exercise and rest can make your body healthier, stronger, and keep you young and energetic. <br>2. <span>Increase immunity and help metabolism</span>.',
        health1: 'Recommended exercise',
        healthText1: 'Slow cycling, walking (4.8km/h), calisthenics, swimming (slow)',
      },
      immunity: {
        label: 'Immunity risk criteria',
        category0: 'Good immunity',
        category1: 'Low immunity',
        category2: 'Immune bias',
        category3: 'Poor immunity',
        default0: 'No risk items (including allergies)',
        default1: '${min} risk item (including allergy)',
        default1_: 'More than ${min} risk items (including allergy)',
        tip: 'BMI and meto are not involved in immunity calculation',
        tipChuangwei: 'Meto are not involved in immunity calculation',
        health0: 'How to maintain normal immunity',
        healthText0: 'It requires a healthy diet, proper exercise, maintaining a good state of mind, and getting enough sleep.',
        health1: 'Strong immune performance',
        healthText1: '1. Good mental state. <br>2. The wound heals quickly. <br>3, basically do not get sick. <br>4. High quality sleep.',
        health2: 'Ways to boost immunity',
        healthText2: '1. Moderate work and rest, <span>Quit smoking and limit alcohol</span>.<br>2. <span>Supplement protein</span>, vitamins and trace elements, strengthen zinc supplementation, and eliminate the impact of nutritional deficiencies on immune function. Pay attention to <span>fine matching</span> in diet, that is, a mixture of coarse grains and refined grains.<br>3. <span>Strengthen exercise</span> to enhance cardiopulmonary function, such as running, skipping rope, etc.',
      },
    },
    imageLevel: 'Image level',
    riskLevel: 'Risk level',
    riskTip: 'Risk statement',
    category: 'Category',
    risk0: 'Normal',
    risk1: 'Low',
    risk2: 'Medium',
    risk3: 'High',
  },
  chart: {
    sm: {
      title: '<span>The last time ${last}</span><span>Duration</span><span>${s}</span><span>hour</span><span>${f}</span><span>minute</span>',
      text: ', and the average oxygen saturation at night is ${val}%',
      text0: 'You sleep well at night${val}. From 10:00 at night to 6:00 in the morning is the golden sleep time.',
      text1: 'You sleep less at night${val}. From 10:00 at night to 6:00 in the morning is the golden sleep time.'
    },
    xyan: {
      title: '<span>${label}</span><span>Blood oxygen</span><span>${val}</span><span>%</span>'
    },
    hx: {
      title: '<span>${label}</span><span>breathing</span><span>${val}</span><span> times / minute</span>',
      slow: 'Bradycardia',
      normal: 'Normal',
      speed: 'Tachycardia',
    },
    mt: {
      title: '<span>${label}</span><span>Mets value</span><span>${val}</span><span>met</span>',
      low: 'Low intensity',
      medium: 'Medium intensity',
      high: 'High strength',
      text0_3: 'The current exercise intensity is low-intensity state, you can increase your exercise volume appropriately',
      text3_6: 'The current exercise intensity is in a state of moderate intensity, and you can continue to maintain the amount of exercise',
      text6_999: 'The current exercise intensity is high-intensity state, and you can exercise appropriately',
    },
    my: {
      text0: '1. Your current detection is normal',
      text1: '2. No history of allergies',
    },
    tw: {
      title: '<span>${label}</span><span>Body temperature</span><span>${val}</span><span>℃</span>',
    },
    tz: {
      title: '<span>${label}</span><span>BMI</span><span>${val}</span>',
      weight0: 'Thin',
      weight1: 'Normal',
      weight2: 'Fat',
      weight3: 'Obesity',
      weight4: 'Severe obesity',
      weight5: 'Extremely obese',
    },
    xl: {
      title: '<span>${label}</span><span>Heart rate</span><span>${val}</span><span>times/min</span>',
      text: 'Statistics of heart rate data of nearly <span>200 times</span>, heart rate range <span>${d}～${g}times/min</span>; average heart rate <span>${p}times/min</span> span>.',
      d: 'Lowest',
      g: 'Highest',
      p: 'Average',
    },
    xt: {
      title: '<span>${label}</span><span>Blood sugar</span><span>${val}</span><span>mmol/L</span>',
      k: 'Reference blood sugar:',
      d: 'Hypoglycemia',
      z: 'Normal',
      g: 'High blood sugar',
    },
    xy: {
      title: '<span>${label}</span><span>Blood pressure</span><span>${g}/${d}</span><span>mmHg</span>',
      text: 'Blood pressure was detected nearly <span>200</span> times, and abnormal blood pressure was reported <span>${val}</span> times.',
      ss: 'Systolic blood pressure:',
      sz: 'Diastolic blood pressure:',
      g: 'Highest value',
      d: 'Lowest value',
    },
    yl: {
      title: '<span>${label}</span><span>Pressure</span><span>${val}</span>',
      fs: 'Relax',
      zc: 'Normal',
      zd: 'Medium',
      pg: 'High',
    },
  }
}
