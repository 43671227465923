<template>
	<div id="app">
		<router-view class="router-view"></router-view>
	</div>
</template>

<script>
export default {
	name: 'App',
	watch: {
		$route({ query }) {
			const { theme, locale } = query;

			switch (theme) {
				case 'meilan': {
					import('@/scss/theme_meilan.css')
					break;
				}
				case 'chuangwei': {
					import('@/scss/theme_chuangwei.css')
					break;
				}

				case 'kaihao': {
					import('@/scss/theme_kaihao.css')
					break;
				}
			}
			this.$store.theme = theme
			this.$i18n.locale = locale || 'zh-cn'
			console.log('进来后赋值', this.$i18n.locale)
		}
	}
}
</script>

<style lang="scss">
html {
	overflow: hidden;
	background-color: #ffffff;
	user-select: none;
}

#app {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.iziToast-wrapper {
	align-items: center;
	padding: 50px 15px;

	&,
	& [class^="iziToast"] {
		box-sizing: border-box;
	}

	.iziToast-capsule {
		width: auto;
	}

	.iziToast,
	.iziToast-body {
		min-height: auto;
	}

	.iziToast-body,
	.iziToast-texts,
	.iziToast-message {
		margin: 0 !important;
	}

	.iziToast-message {
		color: #fff !important;
	}
}

div,
span,
a,
img {
	outline: 0 none;
}

li {
	list-style: none;
}

img {
	display: block;
}

a {
	text-decoration: none;
}

.mt5 {
	margin-top: 5px;
}

.mt15 {
	margin-top: 15px;
}

.mt50 {
	margin-top: 50px;
}

.mb15 {
	margin-bottom: 15px;
}

.mb20 {
	margin-bottom: 20px;
}

.p10 {
	padding: 10px;
}</style>
