/**
 * 全站http配置
 *
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios'
import I18n from '@/i18n'
import Store from '@/store'

const instance = axios.create()

instance.defaults.timeout = 10000
// instance.defaults.baseURL = '/hr'
// instance.defaults.baseURL = 'https://test.iwhopro.com/howeartest/hr/'	// 内测
instance.defaults.baseURL = 'https://report.iwhopodm.cn/hr/'				// 大陆
// instance.defaults.baseURL = 'https://report.iwhop.com/hr/' 				// 国外

// 返回其他状态吗
instance.defaults.validateStatus = status => {
	return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
instance.defaults.withCredentials = true

// HTTPrequest拦截
instance.interceptors.request.use(config => {
	config.headers['Accept-Language'] = I18n.t('lang')
	if (Store.token) {
		config.headers['token'] = Store.token
	}
	return config
}, error => {
	return Promise.reject(error)
})

// HTTPresponse拦截
instance.interceptors.response.use(res => {
	const status = parseInt(res.data.code || 200)
	const message = res.data.msg || '未知错误'
	if (status !== 200) {
		// 如果请求为非200否者默认统一处理
		return Promise.reject(new Error(message))
	}
	return res.data
}, error => {
	return Promise.reject(new Error(error))
})

export default instance
