import Vue from 'vue'

import router from '@/router'
import API from '@/api'
import Crypto from '@/crypto'

import checkHealth from '@/check_health'
import { toDateMap } from '@/util/date';
import { isEmpty } from '@/util'

const isDecodeURIComponent = true
const isCryptoToken = true

// 全局数据管理
export default new Vue({
	data: {
		// 用户token
		token: null,
		// 用户信息
		user: null,
		// 通过链接传递过来的用户健康状态
		health: null,
		// 最新一次的报表ID
		reportId: null,
		// 健康风险级别标准
		healthyStandard: null,
		// 主题
		theme: '',
		// 语言
		locale: 'zh-cn'
	},
	methods: {
		// 获取并判断链接上是否有token
		async hasToken(query) {
			let token = (query || router.currentRoute.query).token
			if ( !isEmpty(token) && isEmpty(this.token) ) {
				try {
					if (!isCryptoToken) throw new Error('不需要解密')
					if (isDecodeURIComponent) token = decodeURIComponent(token)
					this.token = Crypto.decrypt(token)
					console.log('222')
				} catch (e) {
					console.log('333')
					this.token = token
				}
			}
			return !!this.token
		},
		// 获取并判断是否有用户信息
		async hasUser() {
			if (isEmpty(this.user)) {
				const res = await API.getUserInfo()
				console.log('个人信息', res)
				const user = res.data
				this.user = user
			}
			return !!this.user
		},
		// 获取并判断是否有用户最新健康状态
		async hasHealth(query) {
			if ( isEmpty(this.health) ) {
				const {
					// 心率
					HR, HRTime,
					// 血压
					SBP, DBP, BPTime,
					// 睡眠
					sleep, sleepTime,
					// 血氧
					SaO2, SaO2Time,
					// 血糖
					Glu, GluTime,
					// 体温
					BT, BTTime,
					// 呼吸频率
					RR, RRTime,
					// 压力
					P, PTime,
					// 梅托
					MET, METTime,
					// 分享类型
					type
				} = (query || router.currentRoute.query)
				const health = {}
				if (!isEmpty(HR) && !isEmpty(HRTime)) {
					health['HR'] = parseFloat(HR)
					health['HRTime'] = parseInt(HRTime)
				}
				if (!isEmpty(SBP) && !isEmpty(DBP) && !isEmpty(BPTime)) {
					health['SBP'] = parseFloat(SBP)
					health['DBP'] = parseFloat(DBP)
					health['BPTime'] = parseInt(BPTime)
				}
				if (!isEmpty(sleep) && !isEmpty(sleepTime)) {
					health['sleep'] = parseFloat(sleep)
					health['sleepTime'] = parseInt(sleepTime)
				}
				if (!isEmpty(SaO2) && !isEmpty(SaO2Time)) {
					health['SaO2'] = parseFloat(SaO2)
					health['SaO2Time'] = parseInt(SaO2Time)
				}
				if (!isEmpty(Glu) && !isEmpty(GluTime)) {
					health['Glu'] = parseFloat(Glu)
					health['GluTime'] = parseInt(GluTime)
				}
				if (!isEmpty(BT) && !isEmpty(BTTime)) {
					health['BT'] = parseFloat(BT)
					health['BTTime'] = parseInt(BTTime)
				}
				if (!isEmpty(RR) && !isEmpty(RRTime)) {
					health['RR'] = parseFloat(RR)
					health['RRTime'] = parseInt(RRTime)
				}
				if (!isEmpty(P) && !isEmpty(PTime)) {
					health['P'] = parseFloat(P)
					health['PTime'] = parseInt(PTime)
				}
				if (!isEmpty(MET) && !isEmpty(METTime)) {
					health['MET'] = parseFloat(MET)
					health['METTime'] = parseInt(METTime)
				}
				if (!isEmpty(type)) health['type'] = type
				if (!isEmpty(health)) this.health = health
			}
			return !!this.health
		},
		// 获取并判断是否有最新的报表ID
		async hasReportId(reset = false) {
			if (reset || isEmpty(this.reportId)) {
				const res = await API.findReports(1, 1)
				const records = res.data?.records || []
				const id = records[0] && records[0].daily && records[0].daily[0] && records[0].daily[0].id || null
				this.reportId = id
			}
			return !!this.reportId
		},
		// 获取当前健康状态和心率/血压统计
		async getHealth() {
			const data = { ...this.health || {} }
			try {
				const [ heartRate, bloodPressure ] = await Promise.all([
					API.getHeartRate(),
					API.getBloodPressure()
				])
				if (!isEmpty(heartRate.data)) data['heartRate'] = heartRate.data
				if (!isEmpty(bloodPressure.data)) data['bloodPressure'] = bloodPressure.data
			} catch (e) {
				console.error(e)
			}
			return data
		},
		// 获取并缓存健康风险级别标准
		async getHealthyStandard() {
			if (isEmpty(this.healthyStandard)) {
				const res = await API.getHealthyStandard()
				this.healthyStandard = res.data
			}
			return this.healthyStandard
		},
		// 创建新的报表，hasAllergy是否过敏
		async createReport(hasAllergy) {
			// 获取健康状态和健康标准数据
			const [ health, healthyStandard ] = await Promise.all([
				this.getHealth(),
				this.getHealthyStandard(),
				typeof hasAllergy === 'boolean' ? Promise.resolve() : API.getUserAllergen().then(e => {
					hasAllergy = !isEmpty(e.data)
				}),
			])

			const user = this.user

			const data = { 'timestamp': Math.floor(Date.now() / 1000) }
			try {
				let immunityCount = hasAllergy ? 1 : 0
				// 计算心率
				if (!isEmpty(health.heartRate)) {
					const check = checkHealth(
						'criterion.panel.heartRate.category',
						health.heartRate.average,
						healthyStandard.heartRate.default,
					)
					data.heartRateStatus = check.status
					data.heartRateColor = check.color
					data.heartRateLevel = check.level
					data.heartRateValue = health.HR
					data.heartRateCreateTime = health.HRTime
					data.heartRateAvgValue = health.heartRate.average
					data.heartRateLowestValue = health.heartRate.lowest
					data.heartRateHighestValue = health.heartRate.highest

					if (data.heartRateLevel !== 0 && data.heartRateValue !== 0) immunityCount++
				}
				// 计算血压
				if (!isEmpty(health.bloodPressure)) {
					const shrinkCheck = checkHealth(
						'criterion.panel.bloodPressure.category',
						health.bloodPressure.systolicBloodPressureHighest,
						healthyStandard.bloodPressure.shrink,
					)
					data.systolicBloodPressureStatus = shrinkCheck.status
					data.systolicBloodPressureColor = shrinkCheck.color
					data.systolicBloodPressureLevel = shrinkCheck.level
					data.systolicBloodPressureValue = health.SBP
					data.systolicBloodPressureCreateTime = health.BPTime
			
					data.diastolicBloodPressureStatus = shrinkCheck.status
					data.diastolicBloodPressureColor = shrinkCheck.color
					data.diastolicBloodPressureLevel = shrinkCheck.level
					data.diastolicBloodPressureValue = health.DBP
					data.diastolicBloodPressureCreateTime = health.BPTime

					data.systolicBloodPressureAvgValue = health.SBP
					data.systolicBloodPressureLowestValue = health.bloodPressure.systolicBloodPressureLowest
					data.systolicBloodPressureHighestValue = health.bloodPressure.systolicBloodPressureHighest
					data.diastolicBloodPressureAvgValue = health.DBP
					data.diastolicBloodPressureLowestValue = health.bloodPressure.diastolicBloodPressureLowest
					data.diastolicBloodPressureHighestValue = health.bloodPressure.diastolicBloodPressureHighest
					data.bloodPressureExceptionNumber = health.bloodPressure.exceptionalNumber

					if (data.systolicBloodPressureLevel !== 0 && data.systolicBloodPressureValue !== 0) immunityCount++
				}
				// 计算睡眠
				if (!isEmpty(health.sleep)) {
					const check = checkHealth(
						'criterion.panel.sleep.category',
						health.sleep,
						healthyStandard.sleep.default,
						true,
						false
					)
					data.sleepStatus = check.status
					data.sleepColor = check.color
					data.sleepLevel = check.level
					data.sleepValue = health.sleep
					data.sleepCreateTime = health.sleepTime

					if (data.sleepLevel !== 0 && data.sleepValue !== 0) immunityCount++
				}
				// 计算血氧
				if (!isEmpty(health.SaO2)) {
					const check = checkHealth(
						'criterion.panel.bloodOxygen.category',
						health.SaO2,
						healthyStandard.bloodOxygen.default,
						true,
						false
					)
					data.oxygenStatus = check.status
					data.oxygenColor = check.color
					data.oxygenLevel = check.level
					data.oxygenValue = health.SaO2
					data.oxygenCreateTime = health.SaO2Time

					if (data.oxygenLevel !== 0 && data.oxygenValue !== 0) immunityCount++
				}
				// 计算血糖
				if (!isEmpty(health.Glu)) {
					const dateMap = toDateMap(health.GluTime || Date.now())

					let options;

					// 根据时段获取血糖标准
					if (dateMap.h < 8 || (dateMap.h === 8 && dateMap.i <= 0)) {
						options = healthyStandard.bloodSugar.empty
					} else if (dateMap.h < 10 || (dateMap.h === 10 && dateMap.i <= 30)) {
						options = healthyStandard.bloodSugar.after
					} else if (dateMap.h < 12 || (dateMap.h === 12 && dateMap.i <= 0)) {
						options = healthyStandard.bloodSugar.before
					} else if (dateMap.h < 15 || (dateMap.h === 15 && dateMap.i <= 30)) {
						options = healthyStandard.bloodSugar.after
					} else if (dateMap.h < 18 || (dateMap.h === 18 && dateMap.i <= 0)) {
						options = healthyStandard.bloodSugar.before
					} else if (dateMap.h < 21 || (dateMap.h === 21 && dateMap.i <= 0)) {
						options = healthyStandard.bloodSugar.after
					} else {
						options = healthyStandard.bloodSugar.random
					}

					const check = checkHealth(
						'criterion.panel.bloodSugar.category',
						health.Glu,
						options,
						true,
						false
					)
					data.bloodSugarStatus = check.status
					data.bloodSugarColor = check.color
					data.bloodSugarLevel = check.level
					data.bloodSugarValue = health.Glu
					data.bloodSugarCreateTime = health.GluTime

					if (data.bloodSugarLevel !== 0 && data.bloodSugarValue !== 0) immunityCount++
				}
				// 计算体重指数
				if (!isEmpty(user.weight && user.height)) {
					const stature = user.height / 100
					const bmi = Math.round(user.weight / (stature * stature) * 10) / 10
					const check = checkHealth(
						'criterion.panel.weight.category',
						bmi,
						healthyStandard.weight.default,
						true,
						false
					)
					data.weightStatus = check.status
					data.weightColor = check.color
					data.weightLevel = check.level
					data.weightValue = bmi
					data.weightCreateTime = Math.floor(Date.now() / 1000)
				}
				// 计算体温数据
				if (!isEmpty(health.BT)) {
					const check = checkHealth(
						'criterion.panel.temperature.category',
						health.BT,
						healthyStandard.temperature.default,
						true,
						false
					)
					data.temperatureStatus = check.status
					data.temperatureColor = check.color
					data.temperatureLevel = check.level
					data.temperatureValue = health.BT
					data.temperatureCreateTime = health.BTTime

					if (data.temperatureLevel !== 0 && data.temperatureValue !== 0) immunityCount++
				}
				// 计算呼吸频率
				if (!isEmpty(health.RR)) {
					const check = checkHealth(
						'criterion.panel.breathingRate.category',
						health.RR,
						healthyStandard.breathingRate.default,
					)
					data.breathingRateStatus = check.status
					data.breathingRateColor = check.color
					data.breathingRateLevel = check.level
					data.breathingRateValue = health.RR
					data.breathingRateCreateTime = health.RRTime

					if (data.breathingRateLevel !== 0 && data.breathingRateValue !== 0) immunityCount++
				}
				// 计算压力
				if (!isEmpty(health.P)) {
					const check = checkHealth(
						'criterion.panel.pressure.category',
						health.P,
						healthyStandard.pressure.default,
						true,
						false
					)
					data.pressureStatus = check.status
					data.pressureColor = check.color
					data.pressureLevel = check.level
					data.pressureValue = health.P
					data.pressureCreateTime = health.PTime

					if (data.pressureLevel !== 0 && data.pressureValue !== 0) immunityCount++
				}
				// 计算免疫
				if (!isEmpty(immunityCount)) {
					const check = checkHealth(
						'criterion.panel.immunity.category',
						immunityCount,
						healthyStandard.immunity.default,
					)
					data.immunityStatus = check.status
					data.immunityColor = check.color
					data.immunityLevel = check.level
					data.immunityValue = immunityCount
					data.immunityCreateTime = Math.floor(Date.now() / 1000)
				}
				// 计算梅托
				if (!isEmpty(health.MET)) {
					const check = checkHealth(
						'criterion.panel.meto.category',
						health.MET,
						healthyStandard.meto.default,
					)
					data.metoStatus = check.status
					data.metoColor = check.color
					data.metoLevel = check.level
					data.metoValue = health.MET
					data.metoCreateTime = health.METTime
				}
			} catch (e) {
				console.error(e)
				throw e
			}
			// 创建成功
			const res = await API.createReport(data)
			// 更新报表ID
			this.reportId = res.data.healthReportId

			return res.data.healthReportId
		},
			// 编辑过敏原后更新免疫数据
		async updateReportImmunity(id, hasAllergy) {
			const [ health, healthyStandard ] = await Promise.all([
				API.getReport(id).then(e => e.data),
				this.getHealthyStandard(),
			])

			const immunityValue = Math.max(health.immunityValue + (hasAllergy ? 1 : -1), 0)

			const check = checkHealth(
				'criterion.panel.immunity.category',
				immunityValue,
				healthyStandard.immunity.default,
			)
			
			return await API.createReport({
				...health,
				immunityStatus: check.status,
				immunityColor: check.color,
				immunityLevel: check.level,
				immunityValue: immunityValue,
				immunityCreateTime: Math.floor(Date.now() / 1000),
			})
		}
	},
})
