export const dateMsec = {
    y: 365 * 24 * 60 * 60 * 1000,
    m: 30 * 24 * 60 * 60 * 1000,
    a: 7 * 24 * 60 * 60 * 1000,
    d: 24 * 60 * 60 * 1000,
    h: 60 * 60 * 1000,
    i: 60 * 1000,
    s: 1000
}

export function toDate(time) {
    let date
    if (typeof time === 'object') {
        date = time
    } else if (typeof time === 'string') {
        date = new Date(Date.parse(time))
    } else if (time) {
        if (('' + time).length === 10) time = parseInt(time) * 1000
        date = new Date(time)
    } else {
        date = null
    }
    return date
}

export function toDateMap(time) {
    const date = toDate(time)
    if (!date) return null
    return {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
}

export function formatTime(time, cFormat) {
    const formatObj = toDateMap(time)
    if (!formatObj) return null
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}
