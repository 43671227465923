export default {
  lang: 'zh-cn',
  notFount: "未找到该页面",
  loginAgain: '请退出重登',
  linkNone: '链接参数丢失',
  reportId: '报表ID丢失',
  createWait: {
    creating: '健康报告生成中',
  },
  dataPanel: {
    tip: '实时佩戴手表，监测数据',
  },
  dataStatus: {
    higher: '${num}个高风险项',
    medium: '${num}个中风险项',
    low: '${num}个低风险项',
    not: '${num}个风险项',
    normal: '各项指标正常',
    separator: '，',
    date: '报告生成时间：',
  },
  dataItem: {
    higher: '高风险',
    medium: '中风险',
    low: '低风险',
    normal: '正常',
  },
  listLoad: {
    over: '已经到底了',
    loading: '加载中',
  },
  edit: '编辑',
  confirm: {
    title: '温馨提示',
    cancel: '取消',
    confirm: '确认',
  },
  dataStatusConfirm: {
    cancel: '先去检测数据',
    confirm: '生成健康报告',
    incomplete: '检测到您目前只有身高体重数据，是否要继续生成健康报告？',
    noNew: '检测到您的以下数据非最新数据，您是否要继续生成健康报告？',
    old: '检测到您的以下数据为24小时前监测，您是否要继续生成健康报告？',
    bloodPressure: '血压最后测量时间：',
    bloodOxygen: '血氧最后测量时间：',
    heartRate: '心率最后测量时间：',
    sleep: '睡眠最后测量时间：',
    bloodSugar: '血糖最后测量时间：',
    temperature: '体温最后测量时间：',
  },
  dataExplain: {
    next: '下一页',
    end: '完成',
    page0: {
      cover: 'step_0',
      coverChuangwei: 'step_0_chuangwei',
      title: '健康数据，全面收集',
      text: '全面采集您的健康数据，包括心率、血糖、血压、血氧、睡眠等...',
      textChuangwei: '全面采集您的健康数据，包括心率、血氧、睡眠等...',
    },
    page1: {
      cover: 'step_1',
      title: '智能分析，针对性改善建议',
      text: '智能分析健康数据，根据风险等级，提出针对性改善建议...',
    },
    page2: {
      cover: 'step_2',
      title: '全方位预警，健康生活',
      text: '健康数据及免疫力及时预警，守护您的健康生活',
    },
  },
  allergy: {
    appBar: '健康调查问卷',
    isItAllergic: '请问您之前是否有过敏史？',
    yes: '有',
    no: '无',
    whatAllergies: '请问您对以下哪种物品过敏？',
    inhalation: '吸入式过敏原：',
    ingestion: '食入式过敏原：',
    other: '其它过敏原：',
    not: '其它：',
    next: '下一步',
    submit: '提交',
    tip: '请先选择过敏源',
  },
  reportData: {
    appBar: '一键生成健康报告',
    date: '上次生成报告时间',
    next: '生成最新健康报告',
    sleep: '睡眠时长${val}小时',
    bloodSugar: '${val}',
    breathingRate: '${val}次/分钟',
  },
  reportConclusion: {
    healthy: '健康报告',
    diet: '饮食建议',
    motion: '运动建议',
    wear: '佩戴手表，数据及时预警',
    plan: '去完成今日健身计划',
    exercise: '去完成今日运动',
    causes: '诱因',
    suggest: '改善建议',
    user: {
      age: '${val}岁',
      sex1: '男',
      sex2: '女',
    },
    dataItem: {
      diet: {
        title0: '饮食习惯和建议',
        title1: '推荐食材',
        title2: '不建议食材',
      },
      motion: {
        title0: '运动建议',
      },
    },
    allergen: '过敏源：',
    noAllergen: '无过敏史',
    motionSuggest: '保持规律的运动和作息，能让您的身体更加健康、体质更强，保持年轻和活力！'
  },
  log: {
    appBar: '历史记录',
    date: '{y}年{m}月',
  },
  criterion: {
    appBar: '健康风险级别说明',
    tabBar: {
      heartRate: '心率',
      bloodPressure: '血压',
      sleep: '睡眠',
      bloodOxygen: '血氧',
      bloodSugar: '血糖',
      weight: '体重指数',
      temperature: '体温',
      breathingRate: '呼吸频率',
      pressure: '压力',
      meto: '梅脱',
      immunity: '免疫力',
    },
    panel: {
      heartRate: {
        label: '心率风险标准',
        category0_40: '心率过缓',
        category40_50: '心率过缓',
        category50_60: '心率过缓',
        category60_100: '心率正常',
        category100_120: '心率过速',
        category120_140: '心率过速',
        category140_999: '心率过速',
        default0_1: '<${max}次/分',
        default1_1: '${min}次/分≤${max}次/分',
        default1_0: '>${min}次/分',
        health: '心率正常',
        healthText: '据当前监测结果，心率数据正常。<br><br>健康成人<span>正常心率为60～100次/分钟</span>，理想心率应为55～70次/分钟（运动员的心率较普通成人偏慢，一般为50次/分钟左右），心率在90-100之间时，虽然没有达到心动过速的标准，但是也需要警惕。',
        risk_gs: '1、据当前监测结果，有异常心率症状，请关注心脏状态，必要时请及时就医。<br>2、心动过速可导致<span>心悸、自觉停跳感、伴发胸闷、气促、气短、血压下降、头晕</span>等。',
        risk_gh: '1、据当前监测结果，有异常心率症状，请关注心脏状态，必要时请及时就医。<br>2、心动过缓可能直接导致<span>晕厥、短暂性头晕、头痛、心力衰竭或混乱状态</span>，部分患者可出现胸痛、乏力、呼吸急促等非特异性表现。',
      },
      bloodPressure: {
        label: '血压风险标准',
        highPressure: '收缩压（高压）',
        lowPressure: '舒张压（低压）',
        category0_90: '血压偏低',
        category90_120: '血压较好',
        category120_140: '血压偏高',
        category140_160: '高血压1级',
        category160_180: '高血压2级',
        category180_999: '高血压3级',
        shrink0_1: '≤${max}',
        shrink1_1: '${min}<高压≤${max}',
        shrink1_0: '>${min}',
        diastole0_1: '≤${max}',
        diastole1_1: '${min}<低压≤${max}',
        diastole1_0: '>${min}',
        health0: '血压正常',
        healthText0: '统计近200次血压，近期1次血压状况记录${g}/${d}mmHg，当前血压正常。',
        health1: '正常范围',
        healthText1: '收缩压90～139mmHg<br>舒张压60～89mmHg<br><br>高血压是一种“生活方式疾病，很多日常行为习惯是高血压发生的危险因素。高钠低钾饮食、超重或肥胖、过量饮酒、长期精神紧张、体力活动不足，以及<span>高血压家族史</span>等均可引发此疾病。',
        risk_g: '1、当前血压${g}/${d}mmHg，属于异常状态，要十分警惕突发病，您<span>需要尽快去就医，或者拨打120急救电话</span>。<br>2、高血压可能会导致：头晕，乏力，失眠，食欲不振和消化不良等。严重可以导致：眩晕呕吐，心绞痛，呼吸困难，甚至昏厥等，脑供血不足可以导致<span>脑梗塞和瘫痪</span>。',
        risk_d: '1、统计近200次血压，近期1次血压状况记录${g}/${d}mmHg，当前为血压异常，您需要尽快去就医。<br>2、低血压可能引起<span>头晕、视线模糊、恶心</span>、疲劳、怕冷、脸色苍白等症状。',
      },
      sleep: {
        label: '睡眠风险标准',
        sleepDuration: '睡眠时长',
        category7_999: '睡眠正常',
        category6_7: '睡眠时长偏少',
        category5_6: '睡眠时长偏少',
        category0_5: '睡眠时长偏少',
        default1_0: '≥${min}小时',
        default1_1: '${min}-${max}小时',
        default0_1: '≤${max}小时',
        health: '睡眠健康值',
        healthText: '睡眠划分为<span>快速眼动</span>（Rapid Eye Movement，REM）睡眠和<span>非快速眼动</span>（Non-REM，NREM）睡眠，其中NREM睡眠又包括了浅睡眠和深睡眠。在睡眠过程中，REM睡眠与NREM睡眠交替出现，交替一次称为一个睡眠周期。运动能够很好地改善睡眠质量，增加有效睡眠时间。',
        risk: '1、睡眠不够容易影响白天的工作和学习。容易引起焦虑、情绪低落、烦燥。<br>2、<span>免疫力降低，记忆力退化</span>，引发健康问题，疾病容易入侵。',
        table: {
          head0: '睡眠状态',
          head1: '数值/比例',
          row0_col0: '睡眠时常（夜间）',
          row0_col1: '6-10（小时）',
          row1_col0: '深睡比例',
          row1_col1: '20%-60%',
          row2_col0: '浅睡比例',
          row2_col1: '<55%',
          row3_col0: '快速眼动比例',
          row3_col1: '10%-30%',
        },
      },
      bloodOxygen: {
        label: '血氧风险标准',
        bloodOxygenSaturation: '血氧饱和度',
        category90_999: '血氧较好/血氧正常',
        category80_90: '血氧偏低',
        category70_80: '缺氧状态',
        category0_70: '严重缺氧',
        default1_0: '≥${min}%',
        default1_1: '${min}%≤血氧<${max}%',
        default0_1: '<${max}%',
        health: '血氧正常',
        healthText: '1、人体<span>正常血氧饱和度为90%-100%</span>，血液中含氧量越高，人的新陈代谢就越好。<br>2、当然血氧含量高并不是一个好的现象，人体内的血氧都是有一定的饱和度，过低会造成机体供氧不足，过高会导致体内细胞老化。',
        risk: '目前的当前血氧异常，会出现轻度缺氧，胸闷，气短等症状。',
      },
      bloodSugar: {
        label: '血糖风险标准',
        fastingBloodGlucose: '空腹血糖',
        preprandialBloodGlucose: '餐前血糖',
        postprandialBloodGlucose: '餐后血糖',
        randomBloodGlucose: '随机血糖',
        category0_40: '低血糖',
        category40_44: '血糖偏低',
        category44_70: '血糖正常',
        category70_110: '血糖偏高',
        category110_9990: '血糖高危',
        category44_100: '血糖正常',
        category100_139: '血糖偏高',
        category139_9990: '血糖高危',
        category44_111: '血糖正常',
        category111_139: '血糖偏高',
        imageLevel0_40: '低血糖',
        imageLevel40_44: '低血糖',
        imageLevel44_70: '正常',
        imageLevel70_110: '高血糖',
        imageLevel110_9990: '高血糖',
        default0_1: '血糖<${max}',
        default1_1: '${min}≤血糖<${max}',
        default1_0: '血糖≥${min}',
        health0: '正常成人血糖数据',
        healthText0: '空腹血糖值为4.0～7.0mmol/L<br>餐后血糖值为4.0～10.0mmol/L',
        health1: '高血糖的诱因',
        healthText1: '1、吃含糖分和淀粉多的食物不仅引起肥胖，会导致血糖升高。<br>2、身体其他疾病引起高血糖冠心病等与高血糖有密切关系。<br>3、经常<span>喝酒抽烟，缺少运动，肥胖</span>多脂肪的老年人等。',
        health2: '低血糖的诱因',
        healthText2: '1、注射胰岛素和口服降糖类药物过多，促进胰岛素分泌。<br>2、过度劳累或空腹运动，导致体内血糖水平下降。<br>3、<span>长期熬夜酗酒，重症营养不良</span>和长时间不进食，导致外源性葡萄糖摄入减少。',
        risk: '1、目前血糖为${val}mmol/L，${status}。',
        risk1: '<br>2、低血糖波动、会导致组织缺氧诱发心脑血管疾病，引起跌倒而致外伤，甚至严重的可以导致死亡。<br>3、导致<span>低血糖昏迷</span>，造成严重的中枢神经系统的损害',
        risk2: '<br>2、引起身体的各个组织器官发生病变，可能引起患者出现急、慢性的并发症。<br>3、身体长期处于高血糖状态，可能出现类似于糖尿病的三多一少症状，即多饮、多食、多尿、身体消瘦。',
      },
      weight: {
        label: '体重指数风险标准',
        BMIValue: '体重指数数值',
        category0_185: '体重偏瘦',
        category185_240: '体重正常',
        category240_270: '体重偏胖',
        category270_300: '体重肥胖',
        category300_400: '重度肥胖',
        category400_9990: '极度肥胖',
        default0_1: '体重指数<${max}',
        default1_1: '${min}≤体重指数<${max}',
        default1_0: '体重指数≥${min}',
        health0: '体重正常',
        healthText0: '标准体重是反映和衡量一个人健康状况的重要标志之一。过胖和过瘦都不利于健康，也不会给人以健美感。',
        health1: '如何保持体重标准',
        healthText1: '1、少吃甜食的东西，如糖果和油炸的快餐食品。<br>2、<span>多吃水果和蔬菜</span>会让你更加健康，也可以保护让你少生病。<br>3、要多吃鱼类食品和各种豆制品。<br>4、不论你的体重是否达到标准状态，都要进行有规律的<span>体育锻炼</span>。可以通过跑步增强体质。',
        risk_1: '当前BMI${val}，${status}，请注意加强身体营养。',
        risk1: '当前BMI${val}，当24.0≤BMI＜30.0，${status}。',
        risk2: '当前BMI为${val}，当30.0≤BMI＜40.0，${status}',
        risk3: '当前BMI为${val}，当BMI≥40.0，${status}',
      },
      temperature: {
        label: '体温风险标准',
        bodyTemperatureValue: '体温数值',
        category360_373: '体温正常',
        category373_380: '低热',
        category380_400: '中度发热',
        category400_9990: '高烧',
        default1_1: '${min}℃≤体温<${max}℃',
        default1_0: '体温≥${min}℃',
        health0: '体温正常',
        healthText0: '人体正常体温平均在36～37℃之间（腋窝）<br>超出37.3℃就是发热<br>37.3～38℃是低烧<br>38.～40℃是高烧<br>40℃ 以上随时有生命危险。',
        health1: '处理方式',
        healthText1: '1、低烧不建议直接口服退烧药物，因为退烧药可能会阻碍免疫功能发挥，并对病情判断产生错误影响。<br>2、如果体温一直在38℃以下的低热范围，则首先要考虑物理降温的方式，比如用温水擦拭身体、多喝热水、注意室内通风、减少衣物穿着等；',
        health2: '处理方式',
        healthText2: '当体温超过38.5℃，特别是儿童体温较高时，则需要口服退烧药物降温。目前临床上常用到的退烧药主要有扑热息痛、布洛芬、阿司匹林等！',
        health3: '处理方式',
        healthText3: '当体温超过40℃，应第一时间就医检查，确诊引起发热的根本原因。然后再根据医生建议，选择适合自己的退烧药物。',
        risk1: '您当前体温为${val}°C,  当37.3°C≤体温＜38.0°C，为低烧状态，请注意休息降温。',
        risk2: '您目前体温为${val}℃，当38.0°C≤体温＜40.0°C，为高烧状态,请注意休息降温。',
        risk3: '您当前体温为${val}℃，当体温＞40.0°C，属于重度高烧状态，随时有生命危险，请注意及时就医和进行降温。',
      },
      breathingRate: {
        label: '呼吸频率风险标准',
        respiratoryRateValue: '呼吸频率数值',
        category0_12: '呼吸频率偏低',
        category12_20: '呼吸频率正常',
        category20_999: '呼吸频率偏高',
        default0_1: '<${max}次/分钟',
        default1_1: '${min}次/分钟≤呼吸频率<${max}次/分钟',
        default1_0: '>${min}次/分钟',
        harm: '危害',
        health0: '呼吸频率正常',
        healthText0: '正常成人呼吸频率为每分钟12~20次。',
        health1: '请注意避免呼吸频率过快',
        healthText1: '呼吸频率过快是指每分钟超过24次。一般剧烈活动以后，发热，贫血甲亢，心功能不全，或者精神紧张的时候，都会出现呼吸频率过快。如果平时身体比较好，没有明显引起呼吸过快的诱因，可能水精神紧张，或压力过大造成的。',
        risk: '您当前呼吸频率为<span>${val}次/分</span>，频率偏高，请放松身心，深呼吸。',
      },
      pressure: {
        label: '压力风险标准',
        pressureValue: '压力数值',
        category1_60: '压力正常/轻松',
        category60_80: '压力中等',
        category80_99: '压力偏高',
        default1_1: '${min}≤压力<${max}',
        health0: '轻松',
        healthText0: '1-29放松；30-59正常；60-79中等；80-99偏高',
        health1: '压力正常',
        healthText1: '1-29放松；30-59正常；60-79中等；80-99偏高',
        health2: '释放压力方法',
        healthText2: '1、<span>增强体育运动</span>，增强体质。<br>2、<span>听舒缓的音乐</span>，看喜剧电影，有助于放松自己。<br>3、洗个热水澡，睡觉，让身体得到休息。<br>4、<span>找朋友倾述</span>，说出自己的烦恼，心中的压力得到了释放。',
        health3: '压力过高危害',
        healthText3: '1、注意力不集中，记忆力下降，理解力、创造力下降；经常担忧，烦躁不安，焦虑。<br>2、导致各种心脏疾病：血脂增加、血栓增多、心脏病、中风。<br>3、影响免疫系统的活力，对皮肤造成很大影响。',
        risk2: '目前您的<span>压力值为${val}</span>，属于中度压力状态',
        risk3: '目前您的<span>压力值为${val}</span>，属于高压力状态，请注意深呼吸，保持心情愉快。',
      },
      meto: {
        label: '梅脱风险标准',
        category0_3: '梅脱低强度',
        category3_6: '梅脱中强度',
        category6_999: '梅脱高强度',
        default0_1: '≤${max}mets',
        default1_1: '${min}mets<梅脱≤${max}mets',
        default1_0: '>${min}mets',
        health0: '运动的好处',
        healthText0: '1、保持规律的运动和作息，能让您的身体更加健康、体质更强，保持年轻和活力。<br>2、<span>增加免疫力，帮助代谢</span>。',
        health1: '推荐运动',
        healthText1: '慢速骑行、步行（4.8km/h）、柔软体操，游泳（慢速）',
      },
      immunity: {
        label: '免疫力风险标准',
        category0: '免疫力较好',
        category1: '免疫力低',
        category2: '免疫力偏差',
        category3: '免疫力较差',
        default0: '无风险项（包括过敏）',
        default1: '${min}项风险项（包括过敏）',
        default1_: '${min}项以上风险项（包括过敏）',
        tip: 'BMI和梅脱不参与免疫力计算',
        tipChuangwei: '梅脱不参与免疫力计算',
        health0: '如何维持正常的免疫力',
        healthText0: '需要健康的饮食、适当的运动、保持良好的心态以及充足的睡眠。',
        health1: '免疫能力强的表现',
        healthText1: '1、精神状态佳。<br>2、伤口愈合速度快。<br>3、基本不生病。<br>4、睡眠质量高。',
        health2: '提高免疫力的方法',
        healthText2: '1、适度劳逸，<span>戒烟限酒</span>。<br>2、<span>补充蛋白质</span>、维生素和微量元素，加强补锌，消除营养缺陷对免疫功能的影响。饮食上注意<span>精细搭配</span>，即粗粮、细粮混合着吃。<br>3、<span>加强运动</span>，增强心肺功能，如跑步、跳绳等。',
      },
    },
    imageLevel: '图像等级',
    riskLevel: '风险等级',
    riskTip: '风险提示',
    category: '类别',
    risk0: '正常',
    risk1: '低',
    risk2: '中',
    risk3: '高',
  },
  chart: {
    sm: {
      title: '<span>最近一次${last}</span><span>时长</span><span>${s}</span><span>时</span><span>${f}</span><span>分</span>',
      text: '，夜间的平均血氧饱和度<span>${val}%</span>',
      text0: '您夜间睡眠时长很好${val}。夜间<span>10点</span>至早晨<span>6点</span>是黄金睡眠时间。',
      text1: '您夜间睡眠时长偏少${val}。夜间<span>10点</span>至早晨<span>6点</span>是黄金睡眠时间。'
    },
    xyan: {
      title: '<span>${label}</span><span>血氧</span><span>${val}</span><span>%</span>'
    },
    hx: {
      title: '<span>${label}</span><span>呼吸</span><span>${val}</span><span>次/分钟</span>',
      slow: '呼吸过缓',
      normal: '正常',
      speed: '呼吸过速',
    },
    mt: {
      title: '<span>${label}</span><span>梅脱值</span><span>${val}</span><span>met</span>',
      low: '低强度',
      medium: '中等强度',
      high: '高强度',
      text0_3: '当前运动强度为低强度状态，可以适当增加您的运动量',
      text3_6: '当前运动强度为中强度状态，可以继续保持运动量',
      text6_999: '当前运动强度为高强度状态，可适当运动',
    },
    my: {
      text0: '1、您当前检测无异常',
      text1: '2、无过敏史',
    },
    tw: {
      title: '<span>${label}</span><span>体温</span><span>${val}</span><span>℃</span>',
    },
    tz: {
      title: '<span>${label}</span><span>BMI</span><span>${val}</span>',
      weight0: '偏廋',
      weight1: '正常',
      weight2: '偏胖',
      weight3: '肥胖',
      weight4: '重度肥胖',
      weight5: '极度肥胖',
    },
    xl: {
      title: '<span>${label}</span><span>心率</span><span>${val}</span><span>次/分钟</span>',
      text: '统计近<span>200次</span>心率数据，心率范围<span>${d}～${g}次/分钟</span>；平均心率<span>${p}次/分钟</span>。',
      d: '最低',
      g: '最高',
      p: '平均',
    },
    xt: {
      title: '<span>${label}</span><span>血糖</span><span>${val}</span><span>mmol/L</span>',
      k: '参考血糖：',
      d: '低血糖',
      z: '正常',
      g: '高血糖',
    },
    xy: {
      title: '<span>${label}</span><span>血压</span><span>${g}/${d}</span><span>mmHg</span>',
      text: '检测血压近<span>200</span>次，其中有<span>${val}</span>次报告血压异常。',
      ss: '收缩压：',
      sz: '舒张压：',
      g: '最高值',
      d: '最低值',
    },
    yl: {
      title: '<span>${label}</span><span>压力</span><span>${val}</span>',
      fs: '放松',
      zc: '正常',
      zd: '中等',
      pg: '偏高',
    },
  }
}
