import i18n from '@/i18n'

// 根据当前健康数值读取所在健康标准范围
export default function (key = '', value = -1, options = [], min = false, max = true) {
	let check
	if ( ['criterion.panel.immunity.category'].includes(key) ) {
		check = options.find(e => {
			const i = e.min || 0
			return i === value
		})
		if (!check) check = options[options.length - 1].min < value ? options[options.length - 1] : options[0]
	} else {
		check = options.find(e => {
			const i = e.min || 0
			const a = e.max || 999
			return (min ? i <= value : i < value) && (max ? value <= a : value < a)
		})
		if (!check) check = options.find(e => e.level === 0)
	}

	const color = ['#30ba94', '#ffab2b', '#ff8736', '#d01f01'][check.level]

	if ([
		'criterion.panel.bloodSugar.category',
		'criterion.panel.weight.category',
		'criterion.panel.temperature.category'
	].includes(key)) {
		return {
			status: i18n.t(`${key}${(check.min || 0) * 10}_${(check.max || 999) * 10}`),
			level: check.level,
			color,
		}
	} else if (['criterion.panel.immunity.category'].includes(key)) {
		return {
			status: i18n.t(`${key}${check.min || 0}`),
			level: check.level,
			color,
		}
	} else {
		return {
			status: i18n.t(`${key}${check.min || 0}_${check.max || 999}`),
			level: check.level,
			color,
		}
	}
}
