import axios from 'axios'
import Axios from '@/axios'
import UserAxios from '@/user'

export default class API {
	// 过敏源-列表
	static async getAllergens() {
		return await Axios.get('/healthReport/dietAdvice/allergens')
	}
	// 添加用户过敏原
	static async createAllergen(ids = []) {
		return await Axios.post('/healthReport/dietAdvice/user/allergens', ids)
	}
	// 获取个人过敏原信息
	static async getUserAllergen() {
		console.log('个人信息', Axios.get('/healthReport/dietAdvice/user/info'))
		return await Axios.get('/healthReport/dietAdvice/user/info')
	}
	// 删除个人过敏原信息
	static async deleteUserAllergen() {
		return await Axios.get('/healthReport/dietAdvice/user/delete')
	}

	// 添加用户健康报告
	static async createReport(data) {
		return await Axios.post('/healthReport/user', data)
	}
	// 获取健康报告历史列表
	static async findReports(page = 1, size = 10) {
		return await Axios.get('/healthReport/user/historys', {
			params: {
				'pageNo': page,
				'pageSize': size,
			},
		})
	}
	// 获取健康报告详情
	static async getReport(id) {
		return await Axios.get(`/healthReport/user/historys/${id}`)
	}
	// 获取诱因和改善建议
	static async getCausesSuggest(type, level, status) {
		return await Axios.get('/healthReport/causes/suggest', {
			params: {
				'healthType': type,
				'riskLevel': level,
				'status': status,
			},
		})
	}
	// 获取饮食建议
	static async getDietSuggest(id) {
		return await Axios.get('/healthReport/diet/suggest', {
			params: {
				'healthReportId': id
			},
		})
	}
	// 获取个人信息
	static async getUserInfo() {
		// console.log(await UserAxios.get('/auth/personal/allInfo'))
		return await UserAxios.get('/auth/personal/allInfo', {
			params: {
				'timestamp': Math.floor(Date.now() / 1000),
			},
		})
	}

	// 获取最近200次心率统计数据
	static async getHeartRate(number = 200) {
		return await Axios.get('/health/real-time/statistics/heart-rate', {
			params: {
				'avgNumber': number
			},
		})
	}
	// 获取最近200次血压统计数据
	static async getBloodPressure(number = 200) {
		return await Axios.get('/health/real-time/statistics/blood-pressure', {
			params: {
				'avgNumber': number
			},
		})
	}

	// 获取健康风险级别标准
	static async getHealthyStandard() {
		return await axios.get(`config/healthy_standard.json`)
	}
}
