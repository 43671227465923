import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

// 多语言配置
export default new VueI18n({
    locale: navigator.language.toLowerCase(),
    fallbackLocale: 'en-us',
    messages: {
		'zh-cn': require('@/asset/lang/zh.js').default,
		'en-us': require('@/asset/lang/en.js').default,
    },
})
