import 'izitoast/dist/css/iziToast.css';

import VueIziToast from 'vue-izitoast'

// 消息toast配置设置
export default new VueIziToast({
	backgroundColor: 'rgba(0,0,0,.8)',
	position: 'bottomCenter',
	theme: 'dark',
	locale: 'zh-cn',
	timeout: 2000,
	animateInside: false,
	pauseOnHover: false,
	progressBar: false,
	close: false,
	drag: false,
})
