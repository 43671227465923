import CryptoJS from 'crypto-js';

// token加解密方法
export default class Crypto {
	static key = CryptoJS.enc.Utf8.parse('OUvnJ3cXRSXS3OBD')
	static iv = CryptoJS.enc.Utf8.parse('OUvnJ3cXRSXS3OBD')
	static encrypt(data) {
		const encrypted = CryptoJS.AES.encrypt(data, this.key, { iv: this.iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
		return encrypted.toString(CryptoJS.format.base64)
	}

	static decrypt(data) {
		const decrypted = CryptoJS.AES.decrypt(data, this.key, { iv: this.iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
		return decrypted.toString(CryptoJS.enc.Utf8);
	}
}
